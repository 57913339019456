<template>
  <div>
    <el-form
      :rules="rules"
      :model="propForm"
      ref="propForm"
      label-width="180px"
      label-position="left"
    >
      <el-form-item label="仮登録用メールアドレス" prop="email">
        <el-input
          :value="propForm.email"
          @input="e => handleEmit('email', e)"
        ></el-input>
      </el-form-item>
      <el-form-item label="端末番号" prop="terminalId">
        <el-input
          :value="propForm.terminalId"
          @input="e => handleEmit('terminalId', e)"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'NewCurrencyTemporary',
  props: {
    propForm: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        email: [
          {
            required: true,
            message: '有効なメールアドレスを入力してください',
            trigger: 'blur',
          },
        ],
        terminalId: [
          {
            required: true,
            message: '端末番号を入力してください',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    handleEmit(name, e) {
      this.$emit('changeForm', { ...this.propForm, [name]: e });
    },
  },
};
</script>

<style lang="scss" scoped></style>
