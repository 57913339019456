<script>
import NewCurrencyTemporary from './NewCurrencyTemporary';
import NewCurrencyPreConfirm from './NewCurrencyPreConfirm';
import NewCurrencyPage from './NewCurrencyPage';
import NewCurrencyConfirm from './NewCurrencyConfirm';

export default {
  name: 'NewCurrency',
  components: {
    NewCurrencyTemporary,
    NewCurrencyPreConfirm,
    NewCurrencyPage,
    NewCurrencyConfirm,
  },
  props: {
    state: {
      type: String,
      required: true,
    },
    temporaryForm: {
      type: Object,
      required: true,
    },
    mainForm: {
      type: Object,
      required: true,
    },
    mainProps: {
      type: Object,
      required: true,
    },
  },
  render(createElement) {
    switch (this.state) {
      case 'PRE_REGISTRATION':
        return createElement(NewCurrencyTemporary, {
          props: {
            propForm: this.temporaryForm,
          },
          on: {
            changeForm: (e) => this.$emit('changeTemporaryForm', e),
          },
        });
      case 'PRE_CONFIRM':
        return createElement(NewCurrencyPreConfirm, {
          props: {},
        });
      case 'REGISTRATION':
        return createElement(NewCurrencyPage, {
          props: {
            propForm: this.mainForm,
            ...this.mainProps,
          },
          on: {
            changeForm: (e) => this.$emit('changeMainForm', e),
          },
        });
      case 'CONFIRM':
        return createElement(NewCurrencyConfirm, {
          props: {
            currencyData: this.mainForm,
            ...this.mainProps,
          },
        });

      default:
        return '';
    }
  },
};
</script>
