<template>
  <div id="new-currency-wrapper">
    <p id="new-currency-title">{{ title }}</p>
    <new-currency
      :state="newCurrency.pageState"
      :temporaryForm="temporaryForm"
      :main-form="mainForm"
      :main-props="mainProps"
      @changeMainForm="changeMainForm"
      @changeTemporaryForm="changeTemporaryForm"
    />
    <div>
      <el-button @click="onCancel" v-if="isShowCancel">前へ</el-button>
      <el-button
        type="primary"
        @click="onNext"
        :disabled="!isAbleNext"
        :loading="isLoading"
        >次へ</el-button
      >
    </div>
  </div>
</template>

<script>
import NewCurrency from '../../../components/NewCurrency';
import { mapState, mapActions } from 'vuex';
import { ActionTypes as AdminActions } from '../../../store/modules/admin/actions';
import { ActionTypes as NewCurrencyActions } from '../../../store/modules/newCurrency/actions';

export default {
  name: 'new-currency-page',
  components: {
    NewCurrency,
  },
  data() {
    return {
      temporaryForm: {
        email: '',
        terminalId: '',
      },
      mainForm: {
        name: '',
        unit: '',
        description: '',
        email: '',
        terminalId: '',
        pinCode: '',
        region: '',
        password: '',
        category: {},
      },
    };
  },
  computed: {
    ...mapState(['newCurrency']),
    mainProps() {
      return {
        categories: this.newCurrency.categories,
        regions: this.newCurrency.regions,
      };
    },
    title() {
      switch (this.newCurrency.pageState) {
        case 'PRE_REGISTRATION':
          return '新規通貨 仮登録';
        case 'PRE_CONFIRM':
          return 'メールを送信しました';
        case 'REGISTRATION':
          return '新規通貨 本登録';
        case 'CONFIRM':
          return '登録完了';
      }
    },
    isShowCancel() {
      switch (this.newCurrency.pageState) {
        case 'CONFIRM':
        case 'PRE_REGISTRATION':
          return false;
        case 'REGISTRATION':
        case 'PRE_CONFIRM':
          return true;
      }
    },
    isAbleNext() {
      switch (this.newCurrency.pageState) {
        case 'PRE_CONFIRM':
        case 'CONFIRM':
          return true;
        case 'PRE_REGISTRATION':
          return Object.values(this.temporaryForm).every(x => x.length > 0);
        case 'REGISTRATION':
          return Object.entries(this.mainForm).every(
            ([key, val]) => key === 'category' || (val && val.length > 0)
          );
      }
    },
    isLoading() {
      switch (this.newCurrency.pageState) {
        case 'PRE_REGISTRATION':
          return this.newCurrency.loading.prePost;
        case 'PRE_CONFIRM':
          return false;
        case 'REGISTRATION':
          return this.newCurrency.loading.post;
        case 'CONFIRM':
          return false;
      }
    },
  },
  methods: {
    ...mapActions('newCurrency', [
      NewCurrencyActions.preSignUp,
      NewCurrencyActions.signUp,
      NewCurrencyActions.nextState,
      NewCurrencyActions.prevState,
      NewCurrencyActions.getCategories,
      NewCurrencyActions.getRegions,
    ]),
    initPreForm() {
      this.temporaryForm = {
        email: '',
        terminalId: '',
      };
    },
    initMainForm() {
      this.mainForm = {
        name: '',
        unit: '',
        description: '',
        email: '',
        terminalId: '',
        pinCode: '',
        region: '',
        password: '',
        category: {},
      };
    },
    changeMainForm(e) {
      this.mainForm = e;
    },
    changeTemporaryForm(e) {
      this.temporaryForm = e;
    },
    initTemporaryForm() {
      this.temporaryForm = {
        email: '',
        terminalId: '',
      };
    },
    onCancel() {
      switch (this.newCurrency.pageState) {
        case 'PRE_REGISTRATION':
          this.initTemporaryForm();
          console.log('canceled');
          break;
        case 'PRE_CONFIRM':
        case 'REGISTRATION':
        case 'CONFIRM':
          this.prevState();
          break;
      }
    },
    async onNext() {
      switch (this.newCurrency.pageState) {
        case 'PRE_REGISTRATION':
          await this.preSignUp(this.temporaryForm);
          this.mainForm.email = this.temporaryForm.email;
          break;
        case 'PRE_CONFIRM':
          this.nextState();
          break;
        case 'REGISTRATION':
          const { category, ...pf } = this.mainForm;
          await this.signUp(pf);
          break;
        case 'CONFIRM':
          this.initPreForm();
          this.initMainForm();
          this.nextState();
          break;
      }
    },
  },
  created() {
    this.getRegions();
    this.getCategories();
  },
};
</script>

<style lang="scss" scoped>
#new-currency-wrapper {
  background-color: white;
  padding: 30px;
}

#new-currency-title {
  font-size: 2rem;
}
</style>
