<template>
  <div id="pre-confirm-wrapper">
    <p>仮登録用メールアドレスに、本登録用メールアドレスが送信されました。</p>
  </div>
</template>

<script>
export default {
  name: 'NewCurrencyPreConfirm',
};
</script>

<style lang="scss" scoped>
#pre-confirm-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
