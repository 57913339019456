<template>
  <div class="confirm-wrapper">
    <el-table :data="currencyArray">
      <el-table-column prop="key"></el-table-column>
      <el-table-column prop="value"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'new-currency-confirm',
  props: {
    currencyData: {
      type: Object,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currencyArray() {
      return Object.entries(this.currencyData)
        .filter(([key, val]) => key !== 'category')
        .map(([key, value]) => ({
          key: this.itemName(key),
          value: this.itemValue(key, value),
        }));
    },
    getRegionsName() {
      let regionsName = '';
      for (let i = 0; i < this.regions.length; i++) {
        if (this.regions[i].id === this.currencyData.region) {
          regionsName = this.regions[i].name;
        }
      }
      return regionsName;
    },
  },
  methods: {
    itemName(key) {
      switch (key) {
        case 'name':
          return '通貨名';
        case 'region':
          return '地域';
        case 'email':
          return '登録メールアドレス';
        case 'password':
          return 'パスワード';
        case 'category':
          return 'カテゴリー';
        case 'unit':
          return '通貨単位';
        case 'description':
          return '説明';
        case 'terminalId':
          return '端末番号';
        case 'pinCode':
          return 'ピンコード';
        default:
          return;
      }
    },
    itemValue(key, val) {
      switch (key) {
        case 'password':
          return '*****';
        case 'category':
          return val.name;
        case 'region':
          return this.getRegionsName;
        default:
          return val;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
