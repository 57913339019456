<template>
  <div class="new-currency-page-wrapper">
    <el-form
      :model="propForm"
      :rules="rules"
      ref="propForm"
      label-width="180px"
      label-position="left"
    >
      <el-form-item label="通貨名" prop="name">
        <el-input
          type="text"
          name="name"
          placeholder="例 : 北海道 Do"
          :value="propForm.name"
          @input="e => changeEmit('name', e)"
        ></el-input>
      </el-form-item>
      <el-form-item label="通貨単位" prop="unit">
        <el-input
          type="text"
          name="unit"
          placeholder="例 : pt"
          :value="propForm.unit"
          @input="e => changeEmit('unit', e)"
        ></el-input>
      </el-form-item>
      <el-form-item label="説明" prop="description">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="通貨説明文を入力してください"
          :value="propForm.description"
          @input="e => changeEmit('description', e)"
        ></el-input>
      </el-form-item>
      <el-form-item label="登録メールアドレス" prop="email">
        <el-input
          type="email"
          name="email"
          :value="propForm.email"
          @input="e => changeEmit('email', e)"
        ></el-input>
      </el-form-item>
      <el-form-item label="パスワード" prop="password">
        <el-input
          type="password"
          name="password"
          :value="propForm.password"
          @input="e => changeEmit('password', e)"
        ></el-input>
      </el-form-item>
      <el-form-item label="端末番号" prop="terminalId">
        <el-input
          type="text"
          :value="propForm.terminalId"
          @input="e => changeEmit('terminalId', e)"
        ></el-input>
      </el-form-item>
      <el-form-item label="ピンコード" prop="pinCode">
        <el-input
          type="text"
          :value="propForm.pinCode"
          @input="e => changeEmit('pinCode', e)"
        ></el-input>
      </el-form-item>
      <el-form-item label="地域" prop="region">
        <el-select
          :value="propForm.region"
          @input="e => changeEmit('region', e)"
          filterable
        >
          <el-option
            v-for="r in regions"
            :key="r.id"
            :value="r.id"
            :label="r.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-show="false" label="カテゴリ" prop="category">
        <el-select
          :value="'name' in propForm.category ? propForm.category.name : ''"
          @change="e => changeEmit('category', e)"
          filterable
        >
          <el-option v-for="c in categories" :key="c.id" :value="c">{{
            c.name
          }}</el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'new-currency-page',
  props: {
    propForm: {
      type: Object,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        name: [
          {
            required: true,
            message: '登録通貨の名前を入力してください',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: '登録するメールアドレスを入力してください',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            max: 255,
            message: '説明文は255文字以下にしてください',
            trigger: 'blur',
          },
        ],
        unit: [
          {
            required: true,
            message: '通貨単位を入力してください　例: pt',
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: 'パスワードを入力してください',
            trigger: 'blur',
          },
        ],
        region: [
          {
            required: true,
            message: '通貨の所属する地域を入力してください',
            trigger: 'change',
          },
        ],
        terminalId: [
          {
            required: true,
            message: '登録する端末番号を入力してください',
            trigger: 'blur',
          },
        ],
        pinCode: [
          {
            required: true,
            message: 'ピンコードを入力してください',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs['propForm'].validate(valid => {
        if (valid) {
          this.$emit('onSubmit');
        } else {
          return false;
        }
      });
    },
    changeEmit(key, val) {
      this.$emit('changeForm', {
        ...this.propForm,
        [key]: val,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-currency-page-wrapper {
  width: 100%;
}
</style>
